//import "@transformlearning/task-lms-packages/dist/styles/reset.css";
//import "@transformlearning/task-lms-packages/dist/styles/global.css";
import "../styles/globals.scss";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import Head from "next/head";
import fetchJson from "src/lib/fetchJson";
import { SWRConfig } from "swr";

import theme from "../src/theme";
const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
const customTheme = extendTheme(theme);

function MyApp({ Component, pageProps }) {
  return (
    <SWRConfig
      value={{
        fetcher: fetchJson,
        onError: (err) => {
          console.error(err);
        },
      }}
    >
      <ChakraProvider theme={customTheme}>
        <Head>
          <title>Rent Senter | Antidoping Norge</title>
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <Component {...pageProps} />
      </ChakraProvider>
    </SWRConfig>
  );
}

export default MyApp;
