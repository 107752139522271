import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  styles: {
    global: (props) => ({
      body: {
        fontFamily: "body",
        lineHeight: "base",
      },
      a: {
        color: "efefef",
        _hover: {
          textDecoration: "underline",
        },
      },
      header: {
        fontFamily: "subheading",
      },
      main: {
        bg: "baseLight",
        color: "baseDark",
      },
      nav: {
        bg: "lightBlue",
      },
      footer: {
        fontFamily: "subheading",
        bg: "darkGreen",
        color: "baseLight",
      },
      ".adminNumberInput": {
        backgroundColor: "black !important",
        color: "white !important",
      },
      ".adminNumberInput:disabled": {
        backgroundColor: "#4e4e4e !important",
        color: "white !important",
        opacity: "1 !important",
      },
      ".adminDatePicker": {
        width: "100%",
        height: "100%",
      },
      ".adminDatePicker > div.react-datepicker-wrapper": {
        width: "100%",
        height: "100%",
      },
      ".adminDatePicker > div > div.react-datepicker__input-container": {
        width: "100%",
        height: "100%",
      },
      ".adminDatePicker > div > div.react-datepicker__input-container input": {
        width: "100%",
        height: "100%",
        backgroundColor: "black",
        borderRadius: 8,
        padding: "20px",
      },
      ".customBlackDatePickerWidth": {
        width: "100%",
      },
      ".customBlackDatePickerWidth > div.react-datepicker-wrapper": {
        width: "100%",
      },
      ".customBlackDatePickerWidth > div > div.react-datepicker__input-container":
        {
          width: "100%",
        },
      ".customBlackDatePickerWidth > div > div.react-datepicker__input-container input":
        {
          width: "100%",
          backgroundColor: "black",
          border: "1px solid white",
          borderRadius: 4,
        },
      ".customGrayDatePickerWidth": {
        width: "100%",
      },
      ".customGrayDatePickerWidth > div.react-datepicker-wrapper": {
        width: "100%",
      },
      ".customGrayDatePickerWidth > div > div.react-datepicker__input-container":
        {
          width: "100%",
        },
      ".customGrayDatePickerWidth > div > div.react-datepicker__input-container input":
        {
          width: "100%",
          backgroundColor: "#999999",
          border: "1px solid white",
          borderRadius: 4,
        },

      ".clearDatePicker": {
        width: "100%",
        height: "100%",
      },
      ".clearDatePicker > div.react-datepicker-wrapper": {
        width: "100%",
        height: "100%",
      },
      ".clearDatePicker > div > div.react-datepicker__input-container": {
        width: "100%",
        height: "100%",
      },
      ".clearDatePicker > div > div.react-datepicker__input-container input": {
        width: "100%",
        height: "100%",
        // backgroundColor: "black",
        color: "black",
        borderRadius: 8,
        padding: "20px",
      },
    }),
  },
  components: {
    Button: {
      baseStyle: {
        bg: "lightBlue", // Normally, it is "semibold"
        py: 4,
        px: 8,
        color: "baseLight",
        borderRadius: 0,
        boxShadow: "2xl",
        _focus: {
          boxShadow: "none",
        },
        _hover: {
          boxShadow: "outline",
        },
      },
      variants: {
        ghost: {
          bg: "transparent",
          boxShadow: "none",
          _focus: {
            boxShadow: "none",
          },
          _hover: {
            bg: "transparent",
            boxShadow: "none",
          },
          color: "lightBlue",
        },
        visibleIcon: {
          color: "black",
          bg: "white",
          _focus: {
            color: "black",
            bg: "white",
          },
          _hover: {
            color: "black",
            bg: "white",
          },
        },
      },
    },
    Container: {
      sizes: {
        lg: {
          maxW: "1088px",
        },
        xl: {
          maxW: "1200px",
        },
        "2xl": {
          maxW: "2048px",
        },
      },
    },
    Input: {
      variants: {
        rentSenter: {
          field: {
            bg: "white",
            borderRadius: 0,
            borderColor: "darkGray",
            borderWidth: 1,
          },
        },
        // admin: {
        //   field: {
        //     bg: "#010101",
        //     borderColor: "inherit",
        //     color: "white",
        //     borderWidth: "1px",
        //   },
        //   icon: {
        //     color: "white",
        //   },
        //   "*::placeholder": {
        //     color: "white",
        //     bg: "black",
        //   },
        //   _disabled: {
        //     backgroundColor: "gray.100",
        //   },
        // },
      },
    },

    Radio: {
      baseStyle: {
        bg: "red.500",
        color: "red",
        borderColor: "gray.300",
        icon: {
          bg: "red",
          color: "red",
          border: "1px",
          borderColor: "gray.300",
        },
        control: {
          borderColor: "gray.300",
          _disabled: {
            borderColor: "gray.300",
            bg: "gray.200",
          },
        },
        /*label: {
          fontWeight: "medium",
          color: "gray.900",
        },*/
      },
    },
    Select: {
      variants: {
        rentSenter: {
          bg: "red",
          borderRadius: 0,
          borderColor: "darkGray",
          borderWidth: 1,
        },
        admin: {
          field: {
            bg: "#010101",
            borderColor: "inherit",
            color: "white",
            borderWidth: "1px",
          },
          icon: {
            color: "white",
          },
          "*::placeholder": {
            color: "white",
            bg: "black",
          },
        },
        rentSenterBlue: {
          field: {
            bg: "lightBlue",
            borderColor: "inherit",
            color: "baseLight",
            borderWidth: "1px",
          },
          icon: {
            color: "white",
          },
          "*::placeholder": {
            color: "baseLight",
            bg: "lightBlue",
          },
        },
      },
    },
    Textarea: {
      variants: {
        rentSenter: {
          bg: "white",
          borderRadius: 0,
          borderColor: "darkGray",
          borderWidth: 1,
          placeholder: {
            color: "darkGray",
          },
        },
      },
    },
  },
  colors: {
    highlight: "#97D700",
    baseDark: "#2f373e",
    baseNeutral: "#adc19e",
    baseLight: "#FFFFFF",
    lightBlue: "#27BAEC",
    darkGreen: "#0B3B4A",
    lightGray: "#EAEAEA",
    gray: "#F4F4F4",
    darkGray: "#DBDBDB",
    white: "#FFFFFF",
    yellow: "#FAE918",
  },
  fonts: {
    body: "gotham-book, greta-reg, sans-serif",
    subheading: "gotham-book, sans-serif",
    heading: "gotham-bold, sans-serif",
    logo: "gotham-black, sans-serif",
  },
});

export default theme;
